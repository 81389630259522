import Button from '@/components/atoms/button';
import { NumberDataField } from '@/components/molecules/data-field';
import screens from '@/constants/screens';
import { kebabCase } from '@/utils';
import * as Z from '@iconbuild/izzy';
import { PrintParams } from '@iconbuild/izzy';
import React, { useState } from 'react';

import styled from 'styled-components';
import { useLazyQuery } from '@apollo/client';
import { getPrintForProject as getPrintForProjectQuery } from '@/admin/projects/queries';
import { Project } from '@/generated/hasura.graphql';

const Container = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 0.25rem;
`;

const ErrorContainer = styled.div`
  background: pink;
  color: red;
  padding: 1rem;
  border-radius: 0.25rem;
`;

const FormContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 2rem;

  > * {
    margin: 0.5em 0;
    width: calc(50% - 1rem);

    @media (min-width: ${screens.md}) {
      width: calc(33% - 1.5rem);
    }

    @media (min-width: ${screens['2xl']}) {
      width: calc(25% - 1.5rem);
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
`;

// Header for print quantities.
const Header = styled.h3`
  font-size: 1.125rem;
  font-weight: 500;
  padding: 0 1.25rem 0.5rem 0;
  width: calc(100% - 16px);

  * ~ & {
    margin-top: 2rem;
  }
`;

const defaultParams: PrintParams = {
  cornerSmoothingInches: 0,
  infillSpeedPercent: 1,
  layerHeightInches: 0.75,
  minTravelHeightInches: 2,
  minTravelHeightBetweenItemsInches: 2,
  endExtrusionOffsetSeconds: 0,
  startExtrusionOffsetSeconds: 0,
  printInchesPerSecond: 10,
  stopExtrusionLookaheadSeconds: 0,
  startExtrusionLookaheadSeconds: 0,
  travelInchesPerSecond: 10,
  verticalTravelInchesPerSecond: 2,
  wipeInches: 0,
  wipeSpeedPercent: 1.0,
  travelOffsetInches: 2,
  beadVolumePercent: 0.75,
};

interface GCodeProps {
  sitePlan: Z.SitePlan | null;
  title: string;
  project: Pick<Project, 'id' | 'updated_at'>;
}

const GCode = ({ sitePlan = null, title = 'print', project }: GCodeProps) => {
  const [settings, setSettings] = useState<PrintParams>(defaultParams);
  const [isGeneratingGCode, setIsGeneratingGCode] = useState<boolean>(false);

  const [
    getPrintForProject,
    {
      loading: isPrintForProjectLoading,
      data: printData,
      error: getPrintForProjectError,
      called: getPrintForProjectCalled,
      refetch: refetchPrintForProject,
    },
  ] = useLazyQuery(getPrintForProjectQuery);

  const exportPrintPath = async () => {
    // Lazy queries have to be specifically re-fetched
    // if they have already been called once
    const result = await (getPrintForProjectCalled
      ? refetchPrintForProject()
      : getPrintForProject({
          variables: {
            projectId: project.id,
          },
          notifyOnNetworkStatusChange: true,
        }));
    if (result.data && result.data.print.length > 0) {
      setTimeout(() => {
        // Create HTMLAnchorElement to use for triggering a download.
        const fileName = `${kebabCase(title)}.json`;
        const link = document.createElement('a');
        link.setAttribute(
          'href',
          `data:text/plain;charset=utf-8,${encodeURIComponent(
            JSON.stringify(result.data.print[0]),
          )}`,
        );
        link.setAttribute('download', fileName);
        link.click();
        link.remove();
      }, 10);
    }
  };

  const downloadGCode = () => {
    if (!sitePlan) return;
    setIsGeneratingGCode(true);
    setTimeout(() => {
      const gCodeLines = Z.sitePlanGCode({
        printParams: settings,
        nominalEAxisSpeed: 0,
        nominalPumpSpeed: 0,
      })(sitePlan);

      // Create HTMLAnchorElement to use for triggering a download.
      const fileName = `${kebabCase(title)}.nc`;
      const link = document.createElement('a');
      link.setAttribute(
        'href',
        `data:text/plain;charset=utf-8,${encodeURIComponent(
          gCodeLines.join('\n'),
        )}`,
      );
      link.setAttribute('download', fileName);
      link.click();
      setIsGeneratingGCode(false);
    }, 250);
  };

  const updateSettings = (key: keyof PrintParams, value: number) => {
    setSettings({
      ...settings,
      [key]: value,
    });
  };

  return (
    <Container>
      {getPrintForProjectError && (
        <ErrorContainer>
          Error: {getPrintForProjectError.message}
        </ErrorContainer>
      )}
      {getPrintForProjectCalled &&
        !isPrintForProjectLoading &&
        printData &&
        printData.print.length === 0 && (
          // Special case since Apollo does not recognize it as a query error
          <ErrorContainer>Error: Print record not found</ErrorContainer>
        )}
      <Header>Input print params</Header>
      <FormContainer>
        {Object.entries(settings).map(([key, value]) => (
          <NumberDataField
            key={key}
            label={key}
            value={value}
            setValue={(val) => updateSettings(key as keyof PrintParams, val)}
          />
        ))}
      </FormContainer>
      <ButtonContainer>
        <Button onClick={isGeneratingGCode ? undefined : downloadGCode}>
          {isGeneratingGCode ? 'Generating...' : 'Generate G-Code'}
        </Button>
        <Button
          onClick={isPrintForProjectLoading ? undefined : exportPrintPath}
        >
          {isPrintForProjectLoading
            ? 'Exporting Print Path'
            : 'Download Print Path'}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default GCode;
