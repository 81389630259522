import gql from 'graphql-tag';

export const getProjects = gql`
  query getProjects {
    project(order_by: { label: asc }, where: { archived: { _eq: false } }) {
      id
      label
      path
      site
      updated_at
    }
  }
`;

export const getProject = gql`
  query getProject($id: Int!) {
    project_by_pk(id: $id) {
      id
      label
      path
      site
      site_plan
      source_data
      archived
      updated_at
      buildos_project_id
    }
  }
`;

export const getPrintForProject = gql`
  query getPrint($projectId: Int!) {
    print(
      where: { project_id: { _eq: $projectId } }
      order_by: { project_updated_at: desc }
      limit: 1
    ) {
      id
      label
      project_id
      site
      created_at
      updated_at
      processing_is_success
      itemized_optimized_site_plan
      smep_summary
    }
  }
`;
