// Admin nav.
export const admin = [
  {
    label: 'Projects',
    icon: 'house',
    link: '/projects',
  },
  {
    label: 'Magma Data',
    icon: 'list',
    link: '/magma-data',
  },
  {
    label: 'QC Data',
    icon: 'list',
    link: '/qc-data',
  },
  {
    label: 'Admixtures',
    icon: 'material',
    link: '/admixtures',
  },
  {
    label: 'Print Data',
    icon: 'nozzle',
    link: '/print-event-data',
  },
  {
    label: 'Log Codes',
    icon: 'box',
    link: '/codes',
  },
];
