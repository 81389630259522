import * as R from 'ramda';

// Capitalize the first letter in a string.
export const capitalize = R.converge(R.concat, [
  R.compose(R.toUpper, R.head),
  R.tail,
]);

// Make entire string kebab-case and remove all non-alphanumeric characters.
export const kebabCase = (str: string) => {
  const matchExp = /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g;
  const matches = str.match(matchExp) || [];
  return (matches && matches.map((x) => x.toLowerCase()).join('-')) || '';
};

// Filter out non-numeric values to cleanly handle "null" defaults.
export const numeric = (values: (number | null)[]): number[] =>
  values.filter((it): it is number => R.is(Number, it));

// Ensure leading "/" for local links.
export const formatTo = (to: string) => {
  if (/^http/i.test(to)) return to;
  return /^\//.test(to) ? to : `/${to}`;
};
