import { GetProjectQuery } from '@/generated/hasura.graphql';
import { navigate } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import { useQuery } from '@apollo/client';
import EmptyPage from '@/admin/empty-page';
import React from 'react';
import { getProject as getProjectQuery } from '../queries';
import ProjectEditor from './project-editor';

// Wrap the EditProject component to handle loading data from the database
// and showing loading/error state while doing so.
const ProjectEditPage = (props: { id?: number } & RouteComponentProps) => {
  if (!props.id) return <EmptyPage sectionLabel="Projects" problem />;
  // Load Projects from DB.
  const { loading, error, data, refetch } = useQuery<GetProjectQuery>(
    getProjectQuery,
    {
      variables: { id: props.id },
    },
  );

  // Render Loading while waiting for a Project data.
  if (loading) return <EmptyPage sectionLabel="Projects" />;

  const project = data?.project_by_pk;

  // Render Error if unable to load Project or ToolPaths.
  if (error || !project) return <EmptyPage sectionLabel="Projects" problem />;

  return (
    <ProjectEditor
      back={() => navigate('/projects')}
      title={project.label}
      project={project}
      refresh={refetch}
      page={props.location?.pathname.split('/')[3] || 'levels'}
    />
  );
};

export default ProjectEditPage;
