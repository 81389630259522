import React from 'react';
import { Router } from '@reach/router';

import SassCalibration from './sass-calibration';

const MiscRouter = () => {
  return (
    <Router>
      <SassCalibration path="/sass/save-factory-cal" />
    </Router>
  );
};

export default MiscRouter;
